/* eslint-disable max-lines */
/* eslint-disable react/boolean-prop-naming */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { GALLERY_WIDTH_PERCENTAGE } from 'StendersComponent/Slider/Slider.config';
import { ChildrenType, MixType, RefType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { noopFn } from 'Util/Common';
import CSS from 'Util/CSS';
import { isRtl } from 'Util/CSS/CSS';

import { SliderComponent } from './Slider.component';
import {
    ACTIVE_SLIDE_PERCENT,
    ANIMATION_DURATION,
    HEIGHT_TRANSITION_SPEED_ON_MOUNT,
    NUDGE_ANIMATION_DURATION,
    NUDGE_TRANSLATE_X
} from './Slider.config';

/** @namespace Scandipwa/Component/Slider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/Slider/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/Slider/Container */
export class SliderContainer extends PureComponent {
    static propTypes = {
        showCrumbs: PropTypes.bool,
        showArrows: PropTypes.bool,
        showCounter: PropTypes.bool,
        activeImage: PropTypes.number,
        onActiveImageChange: PropTypes.func,
        mix: MixType,
        children: ChildrenType.isRequired,
        isInteractionDisabled: PropTypes.bool,
        device: DeviceType.isRequired,
        onClick: PropTypes.func,
        isVertical: PropTypes.bool,
        isHeightTransitionDisabledOnMount: PropTypes.bool,
        sliderHeight: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        sliderRef: RefType,
        isInitialNudge: PropTypes.bool,
        isDraggable: PropTypes.bool,
        slidersCount: PropTypes.number,
        averageSliderWidth: PropTypes.number,
        currentItemsWidth: PropTypes.number,
        sliderContainerDivWidth: PropTypes.number,
        currentItemWidth: PropTypes.number,
        isMultipleSlidesVisible: PropTypes.bool,
        itemsMargin: PropTypes.number,
        customSlideWidth: PropTypes.number,
        isGallery: PropTypes.bool,
        slideInterval: PropTypes.number,
        isPlayNudge: PropTypes.bool,
        isSlideInterval: PropTypes.bool
    };

    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: noopFn,
        showCrumbs: false,
        showArrows: false,
        showCounter: false,
        isInteractionDisabled: false,
        mix: {},
        onClick: null,
        isVertical: false,
        isHeightTransitionDisabledOnMount: false,
        sliderHeight: null,
        sliderRef: null,
        isInitialNudge: false,
        isDraggable: true,
        slidersCount: 0,
        averageSliderWidth: 0,
        currentItemsWidth: 0,
        sliderContainerDivWidth: 0,
        currentItemWidth: 0,
        isMultipleSlidesVisible: false,
        itemsMargin: 0,
        customSlideWidth: 0,
        isGallery: false,
        slideInterval: 0,
        isPlayNudge: false,
        isSlideInterval: false
    };

    state = {
        isInitialNudgeDone: false,
        isAnimationPlaying: false,
        currentTranslateX: 0
    };

    timeoutIDs = [];

    animationTimeoutId = null;

    autoSlideTimer = null;

    sliderWidth = 0;

    draggableRef = createRef();

    sliderRef = createRef();

    containerFunctions = {
        handleDragStart: this.handleInteraction.bind(this, this.handleDragStart),
        handleDrag: this.handleInteraction.bind(this, this.handleDrag),
        handleDragEnd: this.handleInteraction.bind(this, this.handleDragEnd),
        goNext: this.goNext.bind(this),
        goPrev: this.goPrev.bind(this),
        handleClick: this.handleClick.bind(this),
        changeActiveImage: this.changeActiveImage.bind(this),
        getDir: this.getDir.bind(this),
        getSlideWidth: this.getSlideWidth.bind(this),
        getIsSlider: this.getIsSlider.bind(this),
        getSliderRef: this.getSliderRef.bind(this)
    };

    static getDerivedStateFromProps(props, state) {
        const { activeImage, children } = props;
        const { prevActiveImage } = state;

        if (prevActiveImage !== activeImage && children.length !== 1) {
            return { prevActiveImage: activeImage };
        }

        return null;
    }

    componentDidMount() {
        this.addWindowResizeWatcher();
        if (!this.getIsSlider()) {
            return;
        }

        const sliderChildren = this.draggableRef.current.children;
        const sliderWidth = this.draggableRef.current?.offsetWidth || 0;
        this.sliderWidth = sliderWidth;

        if (!sliderChildren || !sliderChildren[0]) {
            return;
        }

        const {
            slideInterval,
            device: {
                isMobile
            },
            isSlideInterval
        } = this.props;

        if (isSlideInterval) {
            this.autoSlideTimer = setInterval(() => {
                this.go();
            // eslint-disable-next-line no-magic-numbers
            }, isMobile ? 3000 : slideInterval);
        }

        const sliderRef = this.getSliderRef();
        CSS.setVariable(sliderRef, 'sliderOpacity', '0');

        // delay setting carousel translate to avoid wrong calculations be made during transition
        const timeoutOne = setTimeout(() => {
            this.setStyleVariablesOnMount();
            // eslint-disable-next-line no-magic-numbers
        }, 0);

        const target = sliderChildren[0].querySelector('img') || sliderChildren[0];

        target.onload = () => {
            const height = target.offsetHeight;
            const sliderHeight = `${ height }px`;
            CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
        };

        const timeoutTwo = setTimeout(() => {
            const height = target.offsetHeight;
            const sliderHeight = `${ height }px`;

            if (height !== 0) {
                CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
            }
        }, ANIMATION_DURATION);

        const timeoutThree = setTimeout(() => CSS.setVariable(sliderRef, 'sliderOpacity', '1'), 0);

        this.timeoutIDs.push(timeoutOne, timeoutTwo, timeoutThree);
    }

    componentDidUpdate(prevProps) {
        const {
            activeImage: prevActiveImage,
            currentItemsWidth: prevCurrentItemsWidth,
            currentItemWidth: prevCurrentItemWidth
        } = prevProps;
        const {
            activeImage,
            currentItemsWidth,
            currentItemWidth,
            isMultipleSlidesVisible
        } = this.props;

        const sliderWidth = this.draggableRef.current?.offsetWidth || 0;
        this.sliderWidth = sliderWidth;
        if (activeImage !== prevActiveImage && this.getIsSlider()) {
            const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();
            this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION));
            this.setTranslateXStyle(newTranslate);
        }

        if (isMultipleSlidesVisible && this.getIsSlider()
            && (currentItemsWidth !== prevCurrentItemsWidth || currentItemWidth !== prevCurrentItemWidth)) {
            const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();
            this.setTranslateXStyle(newTranslate);
        }
        this.initialNudge();
    }

    componentWillUnmount() {
        if (this.timeoutIDs.length) {
            this.timeoutIDs.forEach((timeoutID) => clearTimeout(timeoutID));
        }
        clearInterval(this.autoSlideTimer);
        clearTimeout(this.animationTimeoutId);
    }

    __construct(props) {
        super.__construct(props);

        const {
            activeImage
        } = this.props;

        this.state = {
            prevActiveImage: activeImage
        };
    }

    containerProps() {
        const {
            showCrumbs,
            showArrows,
            showCounter,
            activeImage,
            mix,
            children,
            isVertical,
            averageSliderWidth
        } = this.props;

        return {
            showCrumbs,
            showArrows,
            showCounter,
            activeImage,
            mix,
            children,
            isVertical,
            sliderWidth: this.sliderWidth,
            draggableRef: this.draggableRef,
            sliderRef: this.sliderRef,
            averageSliderWidth
        };
    }

    getDir() {
        const { isVertical } = this.props;

        if (!isVertical && isRtl()) {
            return -1;
        }

        return 1;
    }

    addWindowResizeWatcher() {
        window.addEventListener('resize', () => {
            const { activeImage } = this.props;
            const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();

            this.setTranslateXStyle(newTranslate);

            // Removed animation to avoid image movement while changing window width.
            this.setAnimationSpeedStyle(0);

            const delay = 500;
            const timeoutOne = setTimeout(() => {
                this.setAnimationSpeedStyle();
            }, delay);

            this.timeoutIDs.push(timeoutOne);
        });
    }

    setStyleVariablesOnMount() {
        const {
            sliderHeight,
            isHeightTransitionDisabledOnMount,
            activeImage
        } = this.props;

        const sliderRef = this.getSliderRef();

        if (isHeightTransitionDisabledOnMount) {
            const transitionSpeed = isHeightTransitionDisabledOnMount
                ? 0
                : `${ HEIGHT_TRANSITION_SPEED_ON_MOUNT }ms`;

            CSS.setVariable(
                sliderRef,
                'height-transition-speed',
                transitionSpeed
            );
        }

        if (sliderHeight) {
            CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
        }

        const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();

        this.setTranslateXStyle(newTranslate);
    }

    setTranslateXStyle(translate) {
        const {
            isVertical,
            isDraggable,
            slidersCount,
            averageSliderWidth,
            currentItemsWidth,
            sliderContainerDivWidth,
            currentItemWidth,
            isMultipleSlidesVisible,
            itemsMargin
        } = this.props;

        if (!isDraggable
            || (averageSliderWidth && translate < -(averageSliderWidth * (slidersCount - 1) + Number(itemsMargin)))) {
            return;
        }

        if (isMultipleSlidesVisible
            && (currentItemsWidth + Number(currentItemWidth) + Number(itemsMargin) * 2 < sliderContainerDivWidth
            || sliderContainerDivWidth === 0)) {
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                '0px'
            );

            this.setState({
                currentTranslateX: 0
            });
        } else if (isMultipleSlidesVisible) {
            const currentTranslateX = -(currentItemsWidth - sliderContainerDivWidth
                + Number(itemsMargin) * 2 + Number(currentItemWidth));

            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${ currentTranslateX }px`
            );
            this.setState({ currentTranslateX });
        } else {
            CSS.setVariable(
                this.draggableRef,
                isVertical ? 'translateY' : 'translateX',
                `${ translate }px`
            );
            this.setState({
                currentTranslateX: translate
            });
        }

        this.setState({ isAnimationPlaying: true });

        clearTimeout(this.animationTimeoutId);
        this.animationTimeoutId = setTimeout(() => {
            this.setState({ isAnimationPlaying: false });
        }, ANIMATION_DURATION);
    }

    setAnimationSpeedStyle(animationDuration = ANIMATION_DURATION) {
        CSS.setVariable(this.draggableRef, 'animation-speed', `${ animationDuration }ms`);
    }

    getIsSlider() {
        const { children } = this.props;

        return children.length > 0;
    }

    getSlideWidth() {
        const {
            isVertical,
            isGallery,
            device: { isMobile },
            customSlideWidth
        } = this.props;

        if (customSlideWidth) {
            return customSlideWidth;
        }

        const {
            offsetWidth = 0,
            offsetHeight = 0
        } = this.draggableRef.current || {};

        if (isGallery && isMobile) {
            const galleryOffsetWidth = offsetWidth * GALLERY_WIDTH_PERCENTAGE;
            const galleryOffsetHeight = offsetHeight * GALLERY_WIDTH_PERCENTAGE;

            return isVertical ? galleryOffsetHeight : galleryOffsetWidth;
        }

        return isVertical ? offsetHeight : offsetWidth;
    }

    getSliderRef() {
        const { sliderRef } = this.props;

        return sliderRef || this.sliderRef;
    }

    onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize) {
        const { originalX } = state;
        const { prevActiveImage: prevActiveSlider } = this.state;
        const {
            onActiveImageChange,
            device,
            onClick
        } = this.props;

        if (onClick) {
            onClick();

            return -prevActiveSlider;
        }

        const fullSliderPoss = Math.round(fullSliderSize / slideSize);
        const elementPositionInDOM = this.draggableRef.current.getBoundingClientRect().x;

        const sliderPosition = -prevActiveSlider;
        const realElementPositionInDOM = elementPositionInDOM - lastTranslate;
        const mousePositionInElement = originalX - realElementPositionInDOM;

        if (device.isMobile) {
            return sliderPosition;
        }

        if (slideSize / 2 < mousePositionInElement && -fullSliderPoss < sliderPosition) {
            const activeSlide = sliderPosition - 1;
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (slideSize / 2 > mousePositionInElement && lastTranslate) {
            const activeSlide = sliderPosition + 1;
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        return sliderPosition;
    }

    getFullSliderWidth() {
        const { isVertical } = this.props;
        const { scrollWidth: fullSliderWidth, scrollHeight } = this.draggableRef.current;

        const width = isVertical ? scrollHeight : fullSliderWidth;

        return width - this.getSlideWidth();
    }

    calculateNextSlide(state) {
        const { isVertical } = this.props;
        const {
            translateX,
            translateY,
            lastTranslateX,
            lastTranslateY
        } = state;

        const lastTranslate = isVertical ? lastTranslateY : lastTranslateX;
        const translate = isVertical ? translateY : translateX;

        const { onActiveImageChange } = this.props;

        const slideSize = this.getSlideWidth();

        const fullSliderSize = this.getFullSliderWidth();

        const dir = this.getDir();
        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = dir === 1 ? translate > lastTranslate : translate < lastTranslate;

        if (!translate) {
            return this.onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize);
        }

        if ((dir === 1 && translate >= 0) || (dir === -1 && translate < 0)) {
            onActiveImageChange(0);

            return 0;
        }

        if ((dir === 1 && translate < -fullSliderSize) || (dir === -1 && translate > fullSliderSize)) {
            const activeSlide = Math.round(fullSliderSize / (-slideSize * dir));
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math[dir === 1 ? 'ceil' : 'floor'](activeSlidePosition);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math[dir === 1 ? 'floor' : 'ceil'](activeSlidePosition);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide);

        return activeSlide;
    }

    handleDragStart() {
        this.setAnimationSpeedStyle(0);
    }

    handleDrag(state) {
        const { isVertical } = this.props;
        const { translateX, translateY } = state;
        const translate = isVertical ? translateY : translateX;
        const fullSliderSize = this.getFullSliderWidth();
        const dir = this.getDir();
        const canDrag = dir === 1
            ? translate < 0 && translate > -fullSliderSize
            : translate > 0 && translate < fullSliderSize;

        if (canDrag) {
            this.setTranslateXStyle(translate);
        }
    }

    handleDragEnd(state, callback) {
        const { isVertical } = this.props;
        const activeSlide = this.calculateNextSlide(state);
        const slideSize = this.getSlideWidth();
        const newTranslate = activeSlide * slideSize;

        this.setAnimationSpeedStyle();
        this.setTranslateXStyle(newTranslate);

        if (isVertical) {
            callback({
                originalY: newTranslate,
                lastTranslateY: newTranslate
            });

            return;
        }

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    handleClick(state, callback, e) {
        if (e.type === 'contextmenu') {
            this.handleDragEnd(state, callback);
        }
    }

    handleInteraction(callback, ...args) {
        const {
            isInteractionDisabled,
            isDraggable
        } = this.props;

        if (isInteractionDisabled || !callback || !isDraggable) {
            return;
        }

        callback.call(this, ...args);
    }

    changeActiveImage(activeImage) {
        const { onActiveImageChange } = this.props;

        onActiveImageChange(activeImage);
    }

    go() {
        const {
            activeImage,
            children
        } = this.props;
        const nextImage = activeImage + 1;

        if (nextImage < children.length) {
            this.setAnimationSpeedStyle();
            this.changeActiveImage(nextImage);
        } else {
            this.setAnimationSpeedStyle(0);
            this.changeActiveImage(0);
        }
    }

    goPrev() {
        const { activeImage } = this.props;

        if (activeImage > 0) {
            this.changeActiveImage(activeImage - 1);
        }
    }

    goNext() {
        const {
            activeImage,
            children
        } = this.props;
        const nextImage = activeImage + 1;

        if (nextImage < children.length) {
            this.changeActiveImage(nextImage);
        } else {
            this.changeActiveImage(children.length - 1);
        }
    }

    initialNudgeSetTranslateX(translate) {
        CSS.setVariable(
            this.draggableRef,
            'translateX',
            `${ translate }px`
        );
    }

    initialNudge() {
        const {
            isInitialNudge,
            isDraggable,
            isPlayNudge,
            currentItemsWidth,
            sliderContainerDivWidth,
            itemsMargin,
            currentItemWidth
        } = this.props;
        const {
            isInitialNudgeDone,
            isAnimationPlaying,
            currentTranslateX
        } = this.state;

        if (isAnimationPlaying) {
            return;
        }

        if (currentItemsWidth + Number(currentItemWidth) + Number(itemsMargin) * 2 > sliderContainerDivWidth
            || currentTranslateX === undefined || Math.abs(currentTranslateX) !== 0) {
            return;
        }

        if ((isInitialNudge && isDraggable && !isInitialNudgeDone)
            || (isPlayNudge && isDraggable)) {
            this.setAnimationSpeedStyle(NUDGE_ANIMATION_DURATION);
            this.initialNudgeSetTranslateX(currentTranslateX + NUDGE_TRANSLATE_X);
            const timeoutOne = setTimeout(() => {
                this.setAnimationSpeedStyle(NUDGE_ANIMATION_DURATION);
                this.initialNudgeSetTranslateX(currentTranslateX);
            }, NUDGE_ANIMATION_DURATION);
            const timeoutTwo = setTimeout(() => {
                this.setState({
                    isInitialNudgeDone: true
                });
            }, NUDGE_ANIMATION_DURATION * 2);

            this.timeoutIDs.push(timeoutOne, timeoutTwo);
        }
    }

    render() {
        return (
            <SliderComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderContainer);
